<template>
  <v-card flat>
    <v-card-title>
      <v-row justify="space-between" no-gutters>
        <div>{{ title }}</div>
        <div>
          <v-btn text color="primary" @click="toggleAddLanguageDialog(true)">
            <v-icon small>fal fa-plus-circle</v-icon>
            <span class="ml-1">
              {{ $t("studies.acv.texts.buttons.new-lang") }}
            </span>
          </v-btn>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("studies.acv.texts.tableHeaders.lang") }}
              </th>
              <th class="text-left">
                {{ $t("studies.acv.texts.tableHeaders.text") }}
              </th>
              <th class="text-left">
                {{ $t("studies.acv.texts.tableHeaders.preview") }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lang in itemLanguages" :key="lang">
              <td>{{ lang }}</td>
              <td>
                <v-textarea outlined v-model="item[lang]" />
              </td>
              <td>
                <MarkdownViewer :value="item[lang]" />
              </td>
              <td>
                <v-btn icon @click="onDeleteLanguage(lang)">
                  <v-icon small>fas fa-trash</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <v-row no-gutters></v-row>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

    <!-- New lang Dialog -->
    <ProductACVTextsAddLangDialog
      v-if="showDialog.addLang"
      :value="showDialog.addLang"
      @close="toggleAddLanguageDialog(false)"
      @submit="onSubmitNewLanguage"
    />
  </v-card>
</template>

<script>
export default {
  name: "ProductACVTextsTableDetails",
  props: {
    title: { type: String, default: "" },
    item: { type: Object, default: () => null },
  },
  components: {
    ProductACVTextsAddLangDialog: () =>
      import("@/components/Studies/ACV/ProductACVTextsAddLangDialog"),
    MarkdownViewer: () => import("@/components/Common/Inputs/MarkdownViewer"),
  },
  data() {
    return {
      showDialog: {
        addLang: false,
      },
      itemLanguages: this.item ? Object.keys(this.item) : [],
    };
  },

  methods: {
    toggleAddLanguageDialog(val) {
      this.showDialog.addLang = val;
    },

    onSubmitNewLanguage(form) {
      this.item[form.lang] = form.text;
      this.toggleAddLanguageDialog(false);
      this.itemLanguages = Object.keys(this.item);
    },
    onDeleteLanguage(lang) {
      delete this.item[lang];
      this.itemLanguages = Object.keys(this.item);
    },
  },
};
</script>

<style scoped></style>
